<template>
  <Container>
    <div v-if="product" class="single-product">
      <ProductThumb
        v-if="!loading"
        :id="product.id"
        class="single-product-thumb"
        :price-range="product.priceRange"
        :src="product.imageUrl"
        :alt="product.imageAlt"
        :srcset="imageSrcset(product.imageSrcset)"
        :name="product.name"
        :href="product.urlPath"
        :ga-category="product.gaCategory"
        :rating="product.stars"
      />
      <Button class="single-product-button" :to="product.urlPath" size="medium">
        Shop Now
      </Button>
    </div>
  </Container>
</template>

<script setup>
  import { deserialize } from "@alchemy_cms/json_api"
  import { imageSrcset } from "~/utils/methods"

  const props = defineProps({
    element: {
      type: Object,
      default: () => ({}),
    },
  })
  const { getValue } = useAlchemy()

  const { api } = useApi()
  const { handleError } = useErrorHandling()
  const productId = getValue(props.element, "product")
  const { data: product, pending: loading } = useLazyAsyncData(
    `${props.element.name}-${props.element.id}`,
    async () => {
      try {
        const data = await api(`/jsonapi/product_thumbnails/${productId}`)
        return deserialize(data)
      } catch (error) {
        handleError(error)
      }
    },
  )
</script>

<style lang="scss" scoped>
  .single-product-thumb {
    margin-bottom: $space-s;
  }
  .single-product {
    max-width: 35em;
  }
  .single-product-button {
    width: 100%;
  }
</style>
